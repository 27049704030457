<template>
  <div>
    <base-material-tabs color="secondary" class="my-5" grow>
      <v-tab v-for="(tab, i) in tabs" :key="i">
        {{ tab }}
      </v-tab>
      <!-- ///// General ///// -->
      <v-tab-item>
        <v-row class="mt-12">
          <v-col cols="12" sm="12" md="3" lg="3">
            <total-leads-assigned-to-consultant-statistics-card class="my-12" />
          </v-col>
          <v-col cols="12" sm="12" md="3" lg="3">
            <total-consultant-unassigned-statistic-card  class="my-12" />
          </v-col>
          <v-col cols="12" sm="12" md="3" lg="3">

          </v-col>
          <v-col cols="12" sm="12" md="3" lg="3">

          </v-col>
        </v-row>
        <v-row class="my-12">
          <v-col cols="12" sm="12" md="3" lg="3">

          </v-col>
          <v-col cols="12" sm="12" md="3" lg="3">

          </v-col>
          <v-col cols="12" sm="12" md="3" lg="3">

          </v-col>
          <v-col cols="12" sm="12" md="3" lg="3">

          </v-col>
        </v-row>
      </v-tab-item>
    </base-material-tabs>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import TotalLeadsAssignedToConsultantStatisticsCard from "../../components/Base/StatsCards/ConsultantStatsCards/TotalConsultantLeadsStatisticCard.vue";
import TotalConsultantUnassignedStatisticCard from '../../components/Base/StatsCards/ConsultantStatsCards/TotalConsultantUnassignedStatisticCard.vue';


export default {
  components: {
    TotalLeadsAssignedToConsultantStatisticsCard,
    TotalConsultantUnassignedStatisticCard,
  },
  data() {
    return {
      tab: 0,
      tabs: ["Summary"],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
